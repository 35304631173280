import Header from "./Header";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Loading from "./Loading";
import {useNavigate, useSearchParams} from "react-router-dom";
import {green, grey, red, rfAccessToken} from "../constants/Constants";
import {fetchRedditEntities, mergeTrending} from "../service/RedditService";

const border = 'solid 2px #b619ff';
const none = 'none';

const defaultSubreddits = ['wallstreetbets', 'stocks', 'stockmarket', 'pennystocks'];

const dayFilters = ['1D', '2D', '3D', '4D', '5D', '6D', '7D'];

function Reddit() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [contentError, setContentError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [subreddits, setSubreddits] = useState(searchParams.get('subreddits')?.split(',') || defaultSubreddits);
    const [redditCounts, setRedditCounts] = useState([]);
    const [filteredRedditCountsMap, setFilteredRedditCountsMap] = useState(new Map());
    const [redditTechnicals, setRedditTechnicals] = useState(new Map());
    const [symbol, setSymbol] = useState('');
    const [selectedDayFilter, setSelectedDayFilter] = useState('1D');

    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            navigate("/login");
        }
        try {
            setLoading(true);
            fetchRedditEntities(true)
                .then(response => {
                    const technicalsMap = new Map(Object.entries(response.redditTechnicals));
                    setRedditTechnicals(technicalsMap);
                    setRedditCounts(response.redditCounts);
                    const countsMap = aggregateCounts(response.redditCounts, subreddits, symbol, selectedDayFilter);
                    setFilteredRedditCountsMap(countsMap);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading reddit content:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading reddit content:", error);
            setContentError(true);
            setLoading(false);
            if (error.status === 403) {
                navigate("/login");
            }
        }
    }, []);

    const [wallstreetbetsBorder, setWallstreetbetsBorder] = useState(subreddits.includes('wallstreetbets') ? border : none);
    const [stocksBorder, setStocksBorder] = useState(subreddits.includes('stocks') ? border : none);
    const [stockmarketBorder, setStockmarketBorder] = useState(subreddits.includes('stockmarket') ? border : none);
    const [pennystocksBorder, setPennystocksBorder] = useState(subreddits.includes('pennystocks') ? border : none);

    function handleContentEntryClick(entry) {
        const updatedSubreddits = subreddits.includes(entry)
            ? subreddits.filter(sub => sub !== entry)
            : [...subreddits, entry];

        setSearchParams({subreddits: updatedSubreddits.join(',')});
        setSubreddits(updatedSubreddits);
        const countsMap = aggregateCounts(redditCounts, updatedSubreddits, symbol, selectedDayFilter);
        setFilteredRedditCountsMap(countsMap);

        setWallstreetbetsBorder(updatedSubreddits.includes('wallstreetbets') ? border : none);
        setStocksBorder(updatedSubreddits.includes('stocks') ? border : none);
        setStockmarketBorder(updatedSubreddits.includes('stockmarket') ? border : none);
        setPennystocksBorder(updatedSubreddits.includes('pennystocks') ? border : none);
    }

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        setSymbol(value);
        const countsMap = aggregateCounts(redditCounts, subreddits, value, selectedDayFilter);
        setFilteredRedditCountsMap(countsMap);
    };

    const handleDayFilterClick = (day) => {
        setSelectedDayFilter(day);
        const countsMap = aggregateCounts(redditCounts, subreddits, symbol, day);
        setFilteredRedditCountsMap(countsMap);
    };

    const aggregateCounts = (counts, subreddits, symbol, dayFilter) => {
        const countsMap = new Map();
        const now = Math.floor(Date.now() / 1000);
        const dayFilterSeconds = parseInt(dayFilter) * 24 * 60 * 60;

        counts.forEach(entry => {
            if (subreddits.includes(entry.subreddit) && (!symbol || entry.symbol.startsWith(symbol))) {
                if (now - entry.created <= dayFilterSeconds) {
                    if (!countsMap.has(entry.symbol)) {
                        countsMap.set(entry.symbol, 0);
                    }
                    countsMap.set(entry.symbol, countsMap.get(entry.symbol) + 1);
                }
            }
        });
        return countsMap;
    };

    const tickerInput = (
        <div className={"ticker-input-submit-container"} style={{margin: "auto auto 1rem"}}>
            <input id={"symbol-to-search"}
                   maxLength={5}
                   className={"ticker-input"}
                   value={symbol}
                   placeholder={'Symbol'}
                   onChange={(e) => {
                       setSymbol(e.target.value);
                       handleInputChange(e)
                   }}
                   autoComplete={"off"}
            />
        </div>
    );

    const redditMentionsTable = (
        <div className={"reddit-dashboard-content"} style={{overflow: 'visible', maxHeight: "max-content"}}>
            {filteredRedditCountsMap?.size ? (
                <div style={{marginTop: '2rem'}}>
                    <table className={"dashboard-table fade-in"}>
                        <thead>
                        <tr className={"dashboard-tr dashboard-th"}>
                            <th className={"th-width-20-percent"}>Symbol</th>
                            <th className={"th-width-20-percent"}>Mentions</th>
                            <th className={"th-width-20-percent"}>Change</th>
                            <th className={"th-width-15-percent"}>Price</th>
                        </tr>
                        </thead>
                    </table>
                    <div className={"max-height-600-400"} style={{overflow: 'scroll'}}>
                        <table className={"dashboard-table fade-in"}>
                            <thead>
                            <tr className={"dashboard-tr dashboard-th"}
                                style={{borderBottom: 'none', color: 'transparent', lineHeight: '0'}}>
                                <th className={"th-width-20-percent"}>Symbol</th>
                                <th className={"th-width-20-percent"}>Mentions</th>
                                <th className={"th-width-20-percent"}>Change</th>
                                <th className={"th-width-15-percent"}>Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {[...filteredRedditCountsMap.entries()]
                                .sort((a, b) => b[1] - a[1])
                                .map(([key, value], index) => (
                                    <tr key={index} className={"dashboard-tr"}>
                                        <td className={"dashboard-td"}>
                                            <div className={"dashboard-td-inner"}>
                                                <a href={"/analyze/" + key + '?content=reddit'}
                                                   className={"dashboard-link"}>{key}</a>
                                            </div>
                                        </td>
                                        <td className={"dashboard-td"}>
                                            <div className={"dashboard-td-inner"}>
                                                <a href={"/analyze/" + key + '?content=reddit'}
                                                   className={"dashboard-link"}>{new Intl.NumberFormat('en-US').format(value.toFixed(2))}</a>
                                            </div>
                                        </td>
                                        <td className={"dashboard-td"}>
                                            {!isNaN((redditTechnicals.get(key))?.changePercentage) ?
                                                <div className={"dashboard-td-inner"}>
                                                    <a href={"/analyze/" + key}
                                                       className={"dashboard-link"}
                                                       style={{
                                                           transition: 'none',
                                                           color: redditTechnicals.get(key)?.changePercentage > 0 ? green : redditTechnicals.get(key)?.changePercentage < 0 ? red : grey
                                                       }}>{redditTechnicals.get(key)?.changePercentage > 0 ? '+' : ''}{new Intl.NumberFormat('en-US').format(redditTechnicals.get(key)?.changePercentage.toFixed(2))}%</a>
                                                </div>
                                                : null}
                                        </td>
                                        <td className={"dashboard-td"}>
                                            {!isNaN((redditTechnicals.get(key))?.price) ?
                                                <div className={"dashboard-td-inner"}>
                                                    <a href={"/analyze/" + key}
                                                       className={"dashboard-link"}>${new Intl.NumberFormat('en-US').format(redditTechnicals.get(key)?.price.toFixed(2))}</a>
                                                </div>
                                                : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className={"nothing-here"}>Nothing here..</div>
            )}
        </div>
    )

    const contentBody = (
        <>
            <div className={"section-subheading admin-subheading"} style={{width: "100%", marginTop: 0}}>Trending
                Stocks
            </div>
            {tickerInput}
            <div className={"reddit-dashboard-entries"}>
                <div className={"reddit-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('wallstreetbets')}
                     style={{border: wallstreetbetsBorder}}>r/wallstreetbets
                </div>
                <div className={"reddit-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('stocks')}
                     style={{border: stocksBorder}}>r/stocks
                </div>
                <div className={"reddit-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('stockmarket')}
                     style={{border: stockmarketBorder}}>r/stockmarket
                </div>
                <div className={"reddit-dashboard-entry dashboard-link"}
                     onClick={() => handleContentEntryClick('pennystocks')}
                     style={{border: pennystocksBorder}}>r/pennystocks
                </div>
            </div>
            <div className={"reddit-switch-buttons"}>
                {dayFilters.map((day, index) => (
                    <div key={index} className={"reddit-switch-button"}
                         onClick={() => handleDayFilterClick(day)}
                         style={{border: selectedDayFilter === day ? border : none}}>{day}</div>
                ))}
            </div>
            {
                contentError ?
                    <div className={"error-loading"}>Error loading reddit content</div>
                    :
                    redditMentionsTable
            }
        </>
    );

    return (
        <div className={"login page"}>
            <Header home={false}/>
            <div className={"small-heading"}>REDDIT</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"}>
                    {loading ?
                        <Loading text={"Fetching Content"}/>
                        :
                        contentBody
                    }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Reddit;
