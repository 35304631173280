import Header from "./Header";
import Loading from "./Loading";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {green, grey, red, rfAccessToken} from "../constants/Constants";
import {fetchRedditEntities} from "../service/RedditService";
import {useNavigate} from "react-router-dom";
import {getJwtRole} from "../service/AuthService";
import {fetchAdminUsers} from "../service/AdminService";

function Admin() {
    const navigate = useNavigate();
    const [contentError, setContentError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);


    useEffect(() => {
        if (!localStorage.getItem(rfAccessToken)) {
            navigate("/login");
        } else if (getJwtRole() !== "ROLE_ADMIN") {
            navigate("/");
        }
        try {
            setLoading(true);
            fetchAdminUsers(true)
                .then(response => {
                    setUsers(response);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error loading admin content:", error);
                    setContentError(true);
                    setLoading(false);
                    if (error.status === 403) {
                        navigate("/login");
                    }
                });
        } catch (error) {
            console.error("Error loading admin content:", error);
            setContentError(true);
            setLoading(false);
            if (error.status === 403) {
                navigate("/login");
            }
        }
    }, []);

    const adminContent = (

        <div className={"admin-dashboard-content fade-in"} style={{overflow: 'visible', maxWidth: '1000px'}}
             >
            <div className={"section-subheading admin-subheading center"}>Users</div>
            {!users?.length ?
                <div className={"center"}>No Users
                </div>
                :
                <table className={"dashboard-table"}>
                    <thead>
                    <tr className={"dashboard-tr dashboard-th"}>
                        <th className={""}>First</th>
                        <th className={""}>Last</th>
                        <th className={"th-width-50-percent"}>Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                            <tr key={index} className={"dashboard-tr"}>
                                <td className={"dashboard-td"}>
                                    <div className={"dashboard-td-inner admin-user-td-text"}><a
                                        href={"/admin/user/" + user.id}
                                        className={"dashboard-link"}
                                    >{user.firstName}</a>
                                    </div>
                                </td>
                                <td className={"dashboard-td"}>
                                    <div className={"dashboard-td-inner admin-user-td-text"}><a
                                        href={"/admin/user/" + user.id}
                                        className={"dashboard-link"}
                                    >{user.lastName}</a>
                                    </div>
                                </td>
                                <td className={"dashboard-td"}>
                                    <div className={"dashboard-td-inner admin-user-td-text"}><a
                                        href={"/admin/user/" + user.id}
                                        className={"dashboard-link"}
                                    >{user.email}</a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>

    )

    return (
        <div className={"login page"}>
            <Header/>
            <div className={"small-heading"}>ADMIN</div>
            <div className={"content"}>
                <div className={"dashboard-section enclosed row"} style={{minHeight: '222px'}}>
                    {loading ?
                        <Loading/>
                        :
                        contentError ?
                            <div className={"error-loading"}>Error loading admin content</div>
                            :
                            adminContent
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Admin;