import {refreshToken} from "./AuthService";
import {properties} from "../properties";
import {rfAccessToken, rfRefreshToken} from "../constants/Constants";

export function fetchAdminDashboardInfo(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminDashboardInfo(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch admin dashboard info."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/dashboard", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function createSubscription(firstCall, email, name, charge, date, description) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        createSubscription(false, email, name, charge, date, description).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to create subscription."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/admin/create-subscription", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        const request = {
            email: email,
            name: name,
            charge: charge,
            date: date,
            description: description
        };
        xhttp.send(JSON.stringify(request));
    });
}

export function createPaymentLink(firstCall, email, name, charge, date, description) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        createPaymentLink(false, email, name, charge, date, description).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to create payment link."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/admin/create-payment-link", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        const request = {
            email: email,
            name: name,
            charge: charge,
            date: date,
            description: description
        };
        xhttp.send(JSON.stringify(request));
    });
}

export function fetchAdminDashboardSubscription(firstCall, subscriptionId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminDashboardSubscription(false, subscriptionId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch subscription."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/get-subscription?subscriptionId=" + subscriptionId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function fetchAdminDashboardUser(firstCall, userId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminDashboardUser(false, userId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch subscription."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/get-user?userId=" + userId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function fetchAdminDashboardPaymentLink(firstCall, paymentLinkId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminDashboardPaymentLink(false, paymentLinkId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch payment link."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/get-payment-link?paymentLinkId=" + paymentLinkId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function fetchAdminDashboardCharge(firstCall, chargeId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminDashboardCharge(false, chargeId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch charge."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/get-charge?chargeId=" + chargeId, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}

export function cancelSubscription(firstCall, subscriptionId) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        cancelSubscription(false, subscriptionId).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to cancel subscription."));
                }
            }
        };
        xhttp.open("POST", properties.host + "/admin/cancel-subscription", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);
        const request = {
            subscriptionId: subscriptionId
        };
        xhttp.send(JSON.stringify(request));
    });
}

export function fetchAdminUsers(firstCall) {
    return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem(rfAccessToken)?.replaceAll('"', '');
        let xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    resolve(JSON.parse(this.responseText));
                } else if (this.status === 403 && firstCall) {
                    // If the token is expired, try to refresh it
                    refreshToken().then(newAccessToken => {
                        localStorage.setItem(rfAccessToken, JSON.stringify(newAccessToken));
                        // Retry the original request with the new access token
                        fetchAdminUsers(false).then(resolve).catch(reject);
                    }).catch(() => {
                        localStorage.removeItem(rfAccessToken);
                        localStorage.removeItem(rfRefreshToken);
                        reject({ error: new Error("Failed to refresh token."), status: 403 });
                    });
                } else {
                    reject(new Error("Failed to fetch content."));
                }
            }
        };
        xhttp.open("GET", properties.host + "/admin/users", true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.setRequestHeader("Authorization", "Bearer " + accessToken);

        xhttp.send();
    });
}