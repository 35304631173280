import smallLogo from '../images/LogoSmall.png'
import rfSmallLogo from '../images/RFLogoSmall3.png'
import logoVideo from "../videos/LogoFast.mp4";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {getJwtRole, isUserLoggedIn, logoutCall} from "../service/AuthService";


function Header(props) {

    const navigate = useNavigate();
    const [logoVisible, setLogoVisible] = useState("hidden");
    const [logoVideoVisible, setLogoVideoVisible] = useState("visible");
    const [role, setRole] = useState(getJwtRole());
    const slideInTop = props.home ? " slide-in-top" : "";

    function displayLogo() {
        if (!props.home) {
            // return <img onClick={() => navigate("/")} src={smallLogo} alt={"small-logo"} className={"small-logo"}/>
            return <img onClick={() => navigate("/")} src={rfSmallLogo} alt={"rf-small-logo"} className={"small-logo"}/>
        } else {
            return (
                <div>
                    <video style={{visibility: logoVideoVisible}} className={"logo-video"} playsInline autoPlay muted
                           onEnded={() => {
                               setLogoVideoVisible("hidden");
                               setLogoVisible("visible")
                           }}>
                        <source src={logoVideo} type="video/mp4"/>
                    </video>
                    <img style={{visibility: logoVisible}} onClick={() => navigate("/")} src={rfSmallLogo}
                         alt={"rf-small-logo"} className={"small-logo"}/>
                </div>
            )
        }
    }

    function handleDropDownClick(command) {
        handleIconClick();
        if (command === "logout") {
            try {
                logoutCall(true).then(loginResponse => {
                    if (loginResponse === 500) {
                        window.alert("Could not log out");
                    } else {
                        navigate("/login")
                    }
                })
                    .catch(error => {
                        console.error("Error logging out:", error);
                        window.alert("Could not log out");
                    });
            } catch (error) {
                console.error("Error logging out:", error);
                window.alert("Could not log out");
            }
            // localStorage.removeItem('access-token');
            // navigate("/");
            // } else if (command === "account") {
            //     role === 'ROLE_ADMIN' ? navigate("/admin") : navigate("/account");
        } else if (command === "account") {
            navigate("/account");
        } else if (command === "admin") {
            navigate("/admin");
        } else if (command === "exclusion") {
            navigate("/exclusion");
        } else if (command === "login") {
            navigate("/login");
        } else {
            navigate("/signup");
        }
    }

    const [dropDownHeight, setDropDownHeight] = useState('0');
    const dropDown = isUserLoggedIn() ? (
        <div className={"dropdown"} style={{height: dropDownHeight}}>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("account")}>Account</div>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("logout")}>Log Out</div>
            {role === 'ROLE_ADMIN' &&
                <>
                    <div className={"dropdown-entry"} onClick={() => handleDropDownClick("admin")}>Admin</div>
                    <div className={"dropdown-entry"} onClick={() => handleDropDownClick("exclusion")}>Exclusion</div>
                </>
            }
        </div>
    ) : (
        <div className={"dropdown"} style={{height: dropDownHeight}}>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("login")}>Log In</div>
            <div className={"dropdown-entry"} onClick={() => handleDropDownClick("signup")}>Sign Up</div>
        </div>
    )

    const expandedHeight = role === 'ROLE_ADMIN' ? '140px' : '80px';

    function handleIconClick() {
        setDropDownHeight(dropDownHeight === '0' ? expandedHeight : '0');
    }

    const [middleBorderColor, setMiddleBorderColor] = useState('#b619ff');
    const [transformTop, setTransformTop] = useState('none');
    const [transformBottom, setTransformBottom] = useState('none');
    const [slideMenuWidth, setSlideMenuWidth] = useState('0');
    const [slideLinkOpacity, setSlideLinkOpacity] = useState('0');
    const [hamburgerPosition, setHamburgerPosition] = useState('');

    function handleHamburgerClick() {
        setMiddleBorderColor(middleBorderColor === '#b619ff' ? '#000416' : '#b619ff');
        setTransformTop(transformTop === 'none' ? 'rotate(45deg)' : 'none');
        setTransformBottom(transformBottom === 'none' ? 'rotate(-45deg)' : 'none');
        setSlideMenuWidth(slideMenuWidth === '0' ? '100%' : '0');
        setSlideLinkOpacity(slideLinkOpacity === '0' ? '1' : '0');
        setHamburgerPosition(hamburgerPosition === '' ? 'fixed' : '');
    }

    return (
        <header className={"main-header"}>
            {displayLogo()}
            <div className={"nav-links"}>
                <a href={"/"} className={"nav-link"}>Home</a>
                <a href={"/analyze"} className={"nav-link nav-link-hidden"}>Analyze</a>
                <a href={"/reddit"} className={"nav-link nav-link-hidden"}>Reddit</a>
                <a href={"/filter"} className={"nav-link nav-link-hidden"}>Filter</a>
                <a href={"/notify"} className={"nav-link nav-link-hidden"}>Notify</a>
                <div className={"user-icon-container nav-link-hidden"}>
                    <div className={"user-icon"} onClick={() => handleIconClick()}>
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    {dropDown}
                </div>
            </div>
            <div className={"hamburger"} style={{position: hamburgerPosition}} onClick={() => handleHamburgerClick()}>
                <div className={"hamburger-line"} style={{transform: transformTop}}></div>
                <div className={"hamburger-line"} style={{borderColor: middleBorderColor}}></div>
                <div className={"hamburger-line"} style={{transform: transformBottom}}></div>
            </div>
            <div className={"slide-menu"} style={{width: slideMenuWidth}}>
                <div className={"slide-links"} style={{opacity: slideLinkOpacity}}>
                    <div className={"slide-link"}>
                        <a href={"/"} className={"slide-link-a"}>Home</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/analyze"} className={"slide-link-a"}>Analyze</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/reddit"} className={"slide-link-a"}>Reddit</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/filter"} className={"slide-link-a"}>Filter</a>
                    </div>
                    <div className={"slide-link"}>
                        <a href={"/notify"} className={"slide-link-a"}>Notify</a>
                    </div>
                    <div className={"slide-link"}>
                        {!isUserLoggedIn() ?
                            <a href={"/login"} className={"slide-link-a"}>Log In</a>
                            :
                            <a href={"/account"} className={"slide-link-a"}>Account</a>
                        }
                    </div>
                    {role === 'ROLE_ADMIN' &&
                        <>
                            <div className={"slide-link"}>
                                <a href={"/admin"} className={"slide-link-a"}>Admin</a>
                            </div>
                            <div className={"slide-link"}>
                                <a href={"/exclusion"} className={"slide-link-a"}>Exclusion</a>
                            </div>
                        </>
                    }
                    <div className={"slide-link"}>
                        {!isUserLoggedIn() ?
                            <a href={"/signup"} className={"slide-link-a"}>Sign Up</a>
                            :
                            <div className={"slide-link-a"} onClick={() => {
                                handleDropDownClick("logout");
                                handleHamburgerClick()
                            }}>Log Out</div>
                        }
                    </div>
                </div>
            </div>
            <div className={"heading " + slideInTop}>
                <span className="line"></span>
                <span className="title">REXIUS FINANCE</span>
                <span className="line"></span>
            </div>
        </header>
    )
}

export default Header;