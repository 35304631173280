import './App.css';
import './Responsive.css';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import Analyze from "./components/Analyze";
import Reddit from "./components/Reddit";
import Notify from "./components/Notify";
import Filter from "./components/Filter";
import Exclusion from "./components/Exclusion";
import UserAccount from "./components/UserAccount";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ErrorBoundary from "./components/ErrorBoundary";
import Error from "./components/Error";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Admin from "./components/Admin";

function App() {
    return (
        <ErrorBoundary>
            <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={"/analyze/*"} element={<Analyze/>}/>
                <Route path={"/reddit/*"} element={<Reddit/>}/>
                <Route path={"/notify/*"} element={<Notify/>}/>
                <Route path={"/filter/*"} element={<Filter/>}/>
                <Route path={"/exclusion/*"} element={<Exclusion/>}/>
                <Route path={"/account/*"} element={<UserAccount/>}/>\
                <Route path={"/admin/*"} element={<Admin/>}/>\
                <Route path={"/login/*"} element={<LogIn/>}></Route>
                <Route path={"/signup/*"} element={<SignUp/>}></Route>
                <Route path={"/forgot-password/*"} element={<ForgotPassword/>}></Route>
                <Route path={"/reset-password/*"} element={<ResetPassword/>}></Route>
                <Route path={"/terms/*"} element={<Terms/>}></Route>
                <Route path={"/privacy/*"} element={<Privacy/>}></Route>
                <Route path={"/error/*"} element={<Error/>}></Route>
                <Route path={'*'} element={<Navigate to="/"/>}/>
            </Routes>
        </ErrorBoundary>
    );
}

export default App;
